<template>
  <div class="content-view" :key="key">
    <div class="font-weight-bold mb-4">
      <router-link class="disabled--text" to="/saudi-surprise">Saudi Surprise</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.id }}</span>
    </div>
    <v-card width="100%" outlined>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Edit question</div>
        <div class="text--text">Provide general information about trip.</div>
        <div class="font-weight-bold mt-5 mb-2">Question type</div>
        <v-select
          class="field46"
          v-model="data.question_type"
          :items="typeList"
          placeholder="Select question type"
          item-text="title"
          item-value="name"
          readonly
          outlined
          dense
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-2">Question</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.question_en"
            placeholder="Title"
            readonly
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 ms-md-3"
            v-model="data.question_ar"
            placeholder="عنوان"
            readonly
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-textarea
            class="field46 width100 me-md-3"
            v-model="data.description_en"
            placeholder="Short description"
            readonly
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="field46 width100 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف قصير"
            readonly
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div>
          <span class="font-weight-bold me-5">Photo content</span>
          <v-switch
            hide-details
            @change="save"
            color="primary"
            class="white--text d-inline-block mt-0"
            v-model="data.answer_with_picture"
            inset
          ></v-switch>
        </div>
        <div class="font-weight-bold mt-5 mb-2">Answer</div>
        <draggable :list="data.answers" @change="changeOrder($event)" handle=".handle">
          <div v-for="(i, j) in data.answers" :key="i.id">
            <div class="d-flex flex-row align-center">
              <img class="move-icon handle mb-6 me-2" src="@/assets/icon/move.svg" alt="icon" />
              <div :id="j + 1" class="d-flex flex-md-row flex-column multi-direct width100">
                <v-text-field
                  class="field46 me-md-3"
                  v-model="i.answer_en"
                  placeholder="Answer"
                  @change="i.id ? saveAnswer(i) : ''"
                  :error-messages="answerEnErrors(j + 1)"
                  outlined
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 ms-md-3"
                  v-model="i.answer_ar"
                  placeholder="عنوان"
                  :error-messages="answerArErrors(j + 1)"
                  outlined
                  dense
                  required
                  color="primary"
                  @change="i.id ? saveAnswer(i) : addAnswer(i)"
                  :disabled="!i.answer_en"
                  height="46"
                ></v-text-field>
              </div>
            </div>
            <v-card
              v-if="data.answer_with_picture"
              flat
              class="primary lighten-3 d-flex align-center justify-center mb-5 mt-n2"
              height="200"
              max-width="360"
            >
              <div v-if="!i.picture">
                <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
                <v-btn @click="addPhoto(i)" depressed width="116" height="30" class="primary text-body-2 rounded">Choose photo</v-btn>
              </div>
              <v-img @click="addPhoto(i)" class="rounded-lg link" v-else contain :src="i.picture" height="200"></v-img>
            </v-card>
          </div>
        </draggable>
        <v-btn
          v-if="data.answers.length < 6 && !data.answers.find((i) => i.id == undefined)"
          @click="data.answers.length < 6 ? data.answers.push({}) : ''"
          width="106"
          height="30"
          depressed
          class="primary rounded"
        >
          <span class="text-body-2">Add answer</span>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-10">
        <v-btn @click="save" depressed color="primary" large width="136">Save</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      key: 1,
      file: '',
      typeList: [
        { name: 'single_select', title: 'Single select' },
        { name: 'multi_select', title: 'Multi select' },
      ],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripSSQItem');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip question updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'New answer created successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'photo') {
        this.$notify({
          title: 'Missed photo',
          message: 'Each answer must contain photo content. Please check it. ',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPhoto(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            i.id ? self.updatePicture(i.id) : self.addAnswer(i);
            ++self.key;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    changeOrder(e) {
      this.updateNumber(e.moved.element.id, e.moved.newIndex);
    },
    async updatePicture(id) {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store.dispatch('updateTripSSQItemAnswerPicture', { id: id, data: formData }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async updateNumber(id, order) {
      this.error = [];
      const data = new Object();
      data.order = +order;
      await this.$store.dispatch('updateTripSSQItemAnswerOrder', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.question_en = this.data.question_en;
      data.question_ar = this.data.question_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.question_type = this.data.question_type;
      data.answer_with_picture = this.data.answer_with_picture;
      await this.$store
        .dispatch('updateTripSSQItem', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addAnswer(i) {
      this.error = [];
      const formData = new FormData();
      for (var key in i) {
        formData.append(key, i[key]);
      }
      this.file ? formData.append('answer_picture', this.file) : '';
      await this.$store
        .dispatch('createTripSSQItemAnswer', formData)
        .then(() => {
          this.notifi('create');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async saveAnswer(i) {
      this.error = [];
      await this.$store
        .dispatch('updateTripSSQItemAnswer', { id: i.id, data: i })
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    answerEnErrors(i) {
      const errors = [];
      this.error.find((item) => item == `answer_${i}_en__required`) && errors.push('Please enter answer');
      this.error.find((item) => item == `answer_${i}_en__invalid`) && errors.push('Provided answer is not valid');
      return errors;
    },
    answerArErrors(i) {
      const errors = [];
      this.error.find((item) => item == `answer_${i}_ar__required`) && errors.push('Please enter answer');
      this.error.find((item) => item == `answer_${i}_ar__invalid`) && errors.push('Provided answer is not valid');
      return errors;
    },
  },
  watch: {
    error(val) {
      if (val) {
        const photo = this.error.length ? this.error[0].split('__')[0].substr(-7, 7) : '';
        photo == 'picture' ? this.notifi('photo') : '';
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsSSQItem;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripSSQItem');
  },
};
</script>

<style lang="scss">
.content-view {
  .v-input {
    max-width: 371px;
  }
}
</style>
